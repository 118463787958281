<template>
  <div class="iss-dashboard">
    <div class="dashboard-wrapper">
<!--      <div class="statistics-card mb-16">-->
<!--        <a-row :gutter="16">-->
<!--          <a-col :span="4"></a-col>-->
<!--          <a-col :span="20" style="text-align: right">-->
<!--            <a-space :size="16">-->
<!--              <a-button-->
<!--                :type="dayButton === 0 && 'primary'"-->
<!--                @click="handleFnCilckDay(0)"-->
<!--                >今天</a-button-->
<!--              >-->
<!--              <a-button-->
<!--                :type="dayButton === 7 && 'primary'"-->
<!--                @click="handleFnCilckDay(7)"-->
<!--                >近7天</a-button-->
<!--              >-->
<!--              <a-button-->
<!--                :type="dayButton === 14 && 'primary'"-->
<!--                @click="handleFnCilckDay(14)"-->
<!--                >近14天-->
<!--              </a-button>-->
<!--              <a-button-->
<!--                :type="dayButton === 30 && 'primary'"-->
<!--                @click="handleFnCilckDay(30)"-->
<!--                >近30天</a-button-->
<!--              >-->
<!--              <a-range-picker-->
<!--                format="YYYY-MM-DD"-->
<!--                v-model:value="TimePick"-->
<!--                @change="handleFnOk"-->
<!--                class="mr-16"-->
<!--              >-->
<!--                <template #suffixIcon>-->
<!--                  <CreditCardOutlined />-->
<!--                </template>-->
<!--              </a-range-picker>-->
<!--            </a-space>-->
<!--          </a-col>-->
<!--        </a-row>-->
<!--      </div>-->
      <a-row :gutter="16" class="mb-16">
        <a-col :span="6">
          <div class="statistics-card" flex-attr="task">
            <div class="statistics-title">
              <div class="fontStyle">
                注册用户数
                <a-tooltip
                  title="注册用户数"
                  placement="top"
                  color="#5979f8"
                  overlayClassName="tips"
                >

<!--                  <QuestionCircleOutlined />-->
                </a-tooltip>
              </div>
              <div>
                <span class="fs-36">{{ dataBoard.registerCount }}</span>
                <span class="fs-14"></span>
              </div>
            </div>
            <div class="imgBox">
              <UserOutlined class="icon activeColor"/>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="statistics-card" flex-attr="user">
            <div class="statistics-title">
              <div class="fontStyle">
                激活用户数
                <a-tooltip
                  title="激活用户数"
                  placement="top"
                  color="#5979f8"
                >
                </a-tooltip>
              </div>
              <div>
                <span class="fs-36">{{ dataBoard.activateCount }}</span>
                <span class="fs-14"></span>
              </div>
            </div>
            <div class="imgBox">
              <FileTextOutlined class="icon activeColor"/>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="statistics-card" flex-attr="company">
            <div class="statistics-title">
              <div class="fontStyle">
                付费订阅用户数
                <a-tooltip
                  title="付费订阅用户数"
                  placement="top"
                  color="#5979f8"
                >
                </a-tooltip>
              </div>
              <div>
                <span class="fs-36">{{ dataBoard.subscribeCount }}</span>
                <span class="fs-14"></span>
              </div>
            </div>
            <div class="imgBox">
              <SmileOutlined class="icon activeColor"/>
            </div>
          </div>
        </a-col>
        <a-col :span="6">
          <div class="statistics-card" flex-attr="company">
            <div class="statistics-title">
              <div class="fontStyle">
                总收益
                <a-tooltip
                    title="总收益"
                    placement="top"
                    color="#5979f8"
                >
                </a-tooltip>
              </div>
              <div>
                <span class="fs-36">{{ overviewData.total ? overviewData.total > 0? overviewData.total/ 100 : overviewData.total : 0}}</span>
                <span class="fs-14"></span>
              </div>
            </div>
            <div class="imgBox">
              <FileTextOutlined class="icon activeColor"/>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-16">
        <a-col :span="12">
          <div class="graph-card">
            <div class="fs-16 fw-500 fontStyle">任务类别分布</div>
            <div
              id="category"
              style="width: 100%; height: 100%"
              v-show="show"
            />
          </div>
        </a-col>
        <a-col :span="12">
          <div class="graph-card">
            <div class="fs-16 fw-500 fontStyle">任务数量</div>
            <a-row
              justify="space-between"
              class="statistics-item"
              align="middle "
            >
              <a-col :span="15" class="mt-10">
                <a-row class="font-T flex-align"
                  >{{ campaign.statDate
                  }}<span class="pl-5"
                    >({{ getWeek(campaign.dayOfWeek) }})</span
                  ></a-row
                >
                <a-row class="flex-align" justify="space-between">
                  <a-col class="words" :span="8"
                    ><span class="fs-36 fw-bold number">{{
                      campaign.count
                    }}</span
                    >个</a-col
                  >
                  <a-col :span="8">
                    <span class="fs-10">环比</span>
                    <span
                      :class="
                        parseFloat(campaign.ringRatio) < 0
                          ? 'word-down'
                          : 'word-up'
                      "
                    >
                      <CaretDownFilled
                        class="fs-12"
                        v-if="parseFloat(campaign.ringRatio) < 0"
                      />
                      <CaretUpFilled class="fs-12" v-else />
                      {{ campaign.ringRatio.replace('-', '') }}
                    </span>
                  </a-col>
                  <a-col :span="8">
                    <span class="fs-10">同比</span>
                    <span
                      :class="
                        parseFloat(campaign.periodRatio) < 0
                          ? 'word-down'
                          : 'word-up'
                      "
                    >
                      <CaretDownFilled
                        class="fs-12"
                        v-if="parseFloat(campaign.periodRatio) < 0"
                      />
                      <CaretUpFilled class="fs-12" v-else />
                      {{ campaign.periodRatio.replace('-', '') }}
                    </span>
                  </a-col>
                </a-row>
              </a-col>
              <a-divider type="vertical" class="dividerStyle-T" />
              <a-col :span="6">
                <a-row class="fs-14 words flex-align"
                  >平均<span class="fs-20 fw-bold number pl-5 pr-5">{{
                    campaignAverage
                  }}</span
                  >个</a-row
                >
                <a-row class="fs-14 words flex-align"
                  >合计<span class="fs-20 fw-bold number pl-5 pr-5">{{
                    campaignTotal
                  }}</span
                  >个</a-row
                >
              </a-col>
            </a-row>
            <div id="industry" style="width: 100%; height: 65%" v-show="show" />
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-16">
        <a-col :span="12">
          <div class="graph-card">
            <div class="fs-16 fw-500 fontStyle">新增联系人数量</div>
            <a-row
              justify="space-between"
              class="statistics-item"
              align="middle "
            >
              <a-col :span="15" class="mt-10">
                <a-row class="font-T"
                  >{{ contact.statDate
                  }}<span class="pl-5"
                    >({{ getWeek(contact.dayOfWeek) }})</span
                  ></a-row
                >
                <a-row class="flex-align" justify="space-between">
                  <a-col class="words" :span="8"
                    ><span class="fs-36 fw-bold number">{{
                      contact.count
                    }}</span
                    >人</a-col
                  >
                  <a-col :span="8">
                    <span class="fs-14">环比</span>
                    <span
                      :class="
                        parseFloat(contact.ringRatio) < 0
                          ? 'word-down'
                          : 'word-up'
                      "
                    >
                      <CaretDownFilled
                        class="fs-12"
                        v-if="parseFloat(contact.ringRatio) < 0"
                      />
                      <CaretUpFilled class="fs-12" v-else />
                      {{ contact.ringRatio.replace('-', '') }}
                    </span>
                  </a-col>
                  <a-col :span="8">
                    <span class="fs-14">同比</span>
                    <span
                      :class="
                        parseFloat(contact.periodRatio) < 0
                          ? 'word-down'
                          : 'word-up'
                      "
                    >
                      <CaretDownFilled
                        class="fs-12"
                        v-if="parseFloat(contact.periodRatio) < 0"
                      />
                      <CaretUpFilled class="fs-12" v-else />
                      {{ contact.periodRatio.replace('-', '') }}
                    </span>
                  </a-col>
                </a-row>
              </a-col>
              <a-divider type="vertical" class="dividerStyle-T" />
              <a-col :span="6">
                <a-row class="fs-14 words flex-align"
                  >平均<span class="fs-20 fw-bold number pl-5 pr-5">{{
                    contactAverage
                  }}</span
                  >人</a-row
                >
                <a-row class="fs-14 words flex-align"
                  >合计<span class="fs-20 fw-bold number pl-5 pr-5">{{
                    contactTotal
                  }}</span
                  >人</a-row
                >
              </a-col>
            </a-row>
            <div
              id="ContactNum"
              style="width: 100%; height: 65%"
              v-show="show"
            />
          </div>
        </a-col>
        <a-col :span="12">
          <div class="graph-card">
            <div class="fs-16 fw-500 fontStyle">新增公司数量</div>
            <a-row
              justify="space-between"
              class="statistics-item"
              align="middle "
            >
              <a-col :span="15" class="mt-10">
                <a-row class="font-T"
                  >{{ company.statDate
                  }}<span class="pl-5"
                    >({{ getWeek(company.dayOfWeek) }})</span
                  ></a-row
                >
                <a-row class="flex-align" justify="space-between">
                  <a-col class="words" :span="8"
                    ><span class="fs-36 fw-bold number">{{
                      company.count
                    }}</span
                    >家</a-col
                  >
                  <a-col :span="8">
                    <span class="fs-14">环比</span>
                    <span
                      :class="
                        parseFloat(company.ringRatio) < 0
                          ? 'word-down'
                          : 'word-up'
                      "
                    >
                      <CaretDownFilled
                        class="fs-12"
                        v-if="parseFloat(company.ringRatio) < 0"
                      />
                      <CaretUpFilled class="fs-12" v-else />
                      {{ company.ringRatio.replace('-', '') }}
                    </span>
                  </a-col>
                  <a-col :span="8">
                    <span class="fs-14">同比</span>
                    <span
                      :class="
                        parseFloat(company.periodRatio) < 0
                          ? 'word-down'
                          : 'word-up'
                      "
                    >
                      <CaretDownFilled
                        class="fs-12"
                        v-if="parseFloat(company.periodRatio) < 0"
                      />
                      <CaretUpFilled class="fs-12" v-else />
                      {{ company.periodRatio.replace('-', '') }}
                    </span>
                  </a-col>
                </a-row>
              </a-col>
              <a-divider type="vertical" class="dividerStyle-T" />
              <a-col :span="6">
                <a-row class="fs-14 words flex-align"
                  >平均<span class="fs-20 fw-bold number pl-5 pr-5">{{
                    companyAverage
                  }}</span
                  >家</a-row
                >
                <a-row class="fs-14 words flex-align"
                  >合计<span class="fs-20 fw-bold number pl-5 pr-5">{{
                    companyTotal
                  }}</span
                  >家</a-row
                >
              </a-col>
            </a-row>
            <div
              id="CompanyNum"
              style="width: 100%; height: 65%"
              v-show="show"
            />
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-16">
        <a-col :span="12">
          <div class="graph-card">
            <div class="fs-16 fw-500 title mb-16">
              <span class="lh-32 fontStyle">联系人地域分布</span>
              <div class="change">
                <span
                  @click="showMap = true"
                  class="pic"
                  :type="showMap ? 'primary' : ''"
                  :style="{ color: showMap == true ? '#6E8BFC' : '#86909C' }"
                  >图表</span
                >
                <a-divider type="vertical" class="dividerStyle" />
                <span
                  @click="showMap = false"
                  class="List"
                  :type="showMap ? '' : 'primary'"
                  :style="{ color: showMap == true ? '#86909C' : '#6E8BFC' }"
                  >表格</span
                >
              </div>
            </div>
            <div
              id="ContactMap"
              style="width: 100%; height: 90%"
              v-show="showMap"
            />
            <div id="ContactMapdata" v-show="!showMap">
              <a-table
                :dataSource="mapList"
                :columns="columns"
                :indentSize="0"
                :expandIconColumnIndex="1"
                @change="handleChangePage"
                :scroll="{ y: 185 }"
                class="iss-grid"
              />
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="graph-card">
            <div class="fs-16 fw-500 mb-16">
              <span class="lh-32 fontStyle">联系人行业分布</span>
              <div class="change">
                <span
                  @click="showIndustry = true"
                  class="pic"
                  :type="showIndustry ? 'primary' : ''"
                  :style="{
                    color: showIndustry == true ? '#6E8BFC' : '#86909C',
                  }"
                  >图表</span
                >
                <a-divider type="vertical" class="dividerStyle" />
                <span
                  @click="showIndustry = false"
                  class="List"
                  :type="showIndustry ? '' : 'primary'"
                  :style="{
                    color: showIndustry == true ? '#86909C' : '#6E8BFC',
                  }"
                  >表格</span
                >
              </div>
            </div>

            <div
              id="ContactIndustry"
              style="width: 100%; height: 90%"
              v-show="showIndustry"
            />
            <div id="ContactIndustrydata" v-show="!showIndustry">
              <a-table
                :dataSource="industry"
                :columns="industryCol"
                :scroll="{ y: 185 }"
                class="iss-grid"
              />
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mb-16">
        <a-col :span="12">
          <div class="graph-card">
            <div class="fs-16 fw-500 mb-16">
              <span class="lh-32 fontStyle">联系人职位分布</span>
              <div class="change">
                <span
                  @click="showPosition = true"
                  :type="showPosition ? 'primary' : ''"
                  :style="{
                    color: showPosition == true ? '#6E8BFC' : '#86909C',
                  }"
                  class="pic"
                  >图表</span
                >
                <a-divider type="vertical" class="dividerStyle" />
                <span
                  @click="showPosition = false"
                  :type="showPosition ? '' : 'primary'"
                  :style="{
                    color: showPosition == true ? '#86909C' : '#6E8BFC',
                  }"
                  class="List"
                  >表格</span
                >
              </div>
            </div>
            <div
              id="ContactPosition"
              style="width: 100%; height: 100%"
              v-show="showPosition"
            />
            <div id="ContactPositiondata" v-show="!showPosition">
              <a-table
                :dataSource="position"
                :columns="positioncol"
                :scroll="{ y: 185 }"
                class="iss-grid"
              />
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import china from '../../assets/Json/china.json';
import {
  Col,
  Row,
  // Button,
  Table,
  // Space,
  // DatePicker,
  Tooltip,
  Divider,
} from 'ant-design-vue';
import {
  CaretUpFilled,
  CaretDownFilled,
  // CreditCardOutlined,
  // QuestionCircleOutlined,
  UserOutlined,
  FileTextOutlined,
  SmileOutlined,

} from '@ant-design/icons-vue';
import dashboardApi from '@/api/dashboard';
import moment from 'moment';
import userApi from "@/api/user";
import ordersApi from "@/api/orders";

export default {
  name: 'dashboard',
  components: {
    ARow: Row,
    ACol: Col,
    ADivider: Divider,
    // AButton: Button,
    ATable: Table,
    // ASpace: Space,
    ATooltip: Tooltip,
    // ARangePicker: DatePicker.RangePicker,
    CaretUpFilled,
    CaretDownFilled,
    // CreditCardOutlined,
    // QuestionCircleOutlined,
    UserOutlined,
    FileTextOutlined,
    SmileOutlined,


  },
  data() {
    return {
      taskpie: {},
      showMap: true,
      showIndustry: true,
      showPosition: true,
      show: true,
      columns: [
        {
          dataIndex: 'name',
          title: '省',
          width: '30%',
          ellipsis: true,
          key: 'name',
        },
        {
          dataIndex: 'city',
          title: '城市',
          width: '50%',
          ellipsis: true,
          key: 'city',
        },
        { dataIndex: 'value', title: '数量', width: '20%', key: 'value' },
      ],
      positioncol: [
        { dataIndex: 'attribute', title: '职位', width: '33%', ellipsis: true },
        {
          dataIndex: 'percentage',
          title: '占比',
          width: '33%',
          ellipsis: true,
        },
        { dataIndex: 'count', title: '数量', width: '33%' },
      ],
      TimeData: {
        attribute: '',
        endCreateTime: '',
        startCreateTime: '',
        statEndTime: '',
        statStartTime: '',
      },
      campaignTotal: 0, //新增任务总数
      campaignTotalWithoutSocial: 0,
      campaignAverage: 0, //新增任务平均数
      campaign: {
        statDate: '',
        periodRatio: '',
        ringRatio: '',
      },
      companyAverage: 0, //新增公司平均数
      companyTotal: 0, //新增公司总数
      company: {
        statDate: '',
        periodRatio: '',
        ringRatio: '',
      },
      contactAverage: 0, //新增联系人平均数
      contactTotal: 0, //新增联系人总数
      contact: {
        statDate: '',
        periodRatio: '',
        ringRatio: '',
      },
      position: [],
      industryCol: [
        { dataIndex: 'attribute', title: '行业', width: '33%', ellipsis: true },
        {
          dataIndex: 'percentage',
          title: '占比',
          width: '33%',
          ellipsis: true,
        },
        { dataIndex: 'count', title: '数量', width: '33%' },
      ],
      industry: [],
      mapList: [],
      dayButton: 7,
      TimePick: [
        moment(new Date(new Date().getTime() - 86400000 * 7)),
        moment(new Date(new Date().getTime() - 86400000)),
      ],
      Maxcount: 0,
      dataBoard: {},
      overviewData: {},
    };
  },
  created() {},
  mounted() {
    this.handleFnCilckDay(7);

    // 数据总览
    // 用户列表表头统计数据
    let parmas = {
      type: 1,
      beginTime: '',
      endTime: '',
      userId: 0,
    }
    userApi.getUserDataBoard('', parmas).then((res) => {
      if (res) {
        console.log('res', res)
        this.dataBoard = res
      }
    })
    // 总收益
    ordersApi.getPayOverview('', {}).then(res => {
      if (res) {
        this.overviewData = res

      }
    });

  },
  methods: {
    //获取数据集合
    getInfoCount() {
      dashboardApi.getInfoCount(this.TimeData).then(data => {
        var campaignAttributeList = []; //任务分布数组
        campaignAttributeList = data.campaignAttributeList.map(item => {
          return {
            name: item.attribute,
            value: item.count,
          };
        });

        var campaignDateList = [];
        this.campaignTotal = data.campaignTotal;
        this.campaignTotalWithoutSocial = data.campaignTotalWithoutSocial;
        this.campaignAverage = data.campaignAverage;
        this.campaign = data.campaignDateList;
        campaignDateList = data.campaignDateList.map(item => {
          this.campaign.periodRatio = item.periodRatio;
          this.campaign.ringRatio = item.ringRatio;
          this.campaign.statDate = item.statDate;
          this.campaign.count = item.count;
          this.campaign.dayOfWeek = item.dayOfWeek;
          return {
            date: item.statDate,
            value: item.count,
          };
        });

        var contactList = [];
        this.contactTotal = data.contactTotal;
        this.contactAverage = data.contactAverage;

        contactList = data.contactList.map(item => {
          this.contact.periodRatio = item.periodRatio;
          this.contact.ringRatio = item.ringRatio;
          this.contact.statDate = item.statDate;
          this.contact.count = item.count;
          this.contact.dayOfWeek = item.dayOfWeek;
          return {
            date: item.statDate,
            value: item.count,
          };
        });

        var companyList = [];
        this.companyTotal = data.companyTotal;
        this.companyAverage = data.companyAverage;
        companyList = data.companyList.map(item => {
          this.company.periodRatio = item.periodRatio;
          this.company.ringRatio = item.ringRatio;
          this.company.statDate = item.statDate;
          this.company.count = item.count;
          this.company.dayOfWeek = item.dayOfWeek;
          return {
            date: item.statDate,
            value: item.count,
          };
        });

        var industryList = [];
        this.industry = data.industryList;
        industryList = data.industryList.map(item => {
          return {
            name: item.attribute,
            value: item.count,
          };
        });

        var positionList = [];
        this.position = data.positionList;
        positionList = data.positionList.map(item => {
          return {
            name: item.attribute,
            value: item.count,
          };
        });
        this.$nextTick(() => {
          this.initIndustry(campaignDateList);
          this.initCategory(campaignAttributeList);
          this.initContactNum(contactList);
          this.initCompanyNum(companyList);
          this.initContactIndustry(industryList);
          this.initContactPosition(positionList);
        });
      });
    },

    getAreaCount() {
      dashboardApi.getAreaCount(this.TimeData).then(data => {
        this.mapList = data.map((item, idx) => {
          var str = item.province.replace(
            /省|市|自治区|特别行政区|维吾尔|回族|壮族/g,
            ''
          );
          var children = item.cityList.map((item, index) => {
            return {
              key: idx + '-' + index,
              name: '',
              city: item.city,
              value: item.count,
            };
          });
          return {
            key: idx,
            name: str,
            city: '',
            value: item.count,
            children: children,
          };
        });
        this.Maxcount = this.mapList[0].value;
        console.log(this.mapList);
        this.initContactMap(this.mapList);
      });
    },
    initChart(id, topic, option) {
      setTimeout(() => {
        var myChart = echarts.init(document.getElementById(id), topic);
        myChart.clear();
        myChart.setOption(option);
        this.$nextTick(() => {
          window.addEventListener('resize', () => {
            //监听浏览器窗口大小改变
            //浏览器变化执行动作
            myChart.resize();
          });
        });
      }, 500);
    },
    //渲染任务分布饼图
    initCategory(List) {
      let dataset = List;
      // 绘制图表
      let option = {};
      (option.title = {
        text: '', //主标题
        subtext: '', //副标题
        x: 'center', //x轴方向对齐方式
      }),
        (option.tooltip = {
          trigger: 'item',
          formatter: '{a} <br/>{b} : {c} ({d}%)',
        }),
        (option.grid = {
          left: '10%',
          bottom: '10%',
          top: '5%',
          right: '5%',
        }),
        (option.legend = {
          orient: 'vertical',
          width: '30%',
          left: '55%',
          top: '20%',
          icon: 'circle',
          formatter: function (name) {
            var total = 0;
            var target;
            for (var i = 0, l = dataset.length; i < l; i++) {
              total += dataset[i].value;
              if (dataset[i].name == name) {
                target = dataset[i].value;
              }
            }
            let arr = [
              '{a|' +
                name +
                '}{b|' +
                ((target / total) * 100).toFixed(2) +
                '%}',
            ];
            if (total == 0) {
              arr = ['{a|' + name + '}{b|0.00%}'];
            }

            return arr.join('\n');
          },
          textStyle: {
            rich: {
              a: {
                width: 120,
                fontSize: 14,
                color: '#5f5f5f',
              },
              b: {
                align: 'right',
                fontSize: 16,
                color: '#313D5F',
                fontWidth: 'bold',
              },
            },
          },
        }),
        (option.series = [
          {
            name: '任务类别',
            type: 'pie',
            radius: ['60%', '70%'],
            center: ['25%', '50%'],
            data: dataset,
            label: {
              show: true,
              position: 'center',
              fontWeight: 'bold',
              fontSize: 36,
              formatter: function () {
                var total = 0;
                for (var i = 0, l = dataset.length; i < l; i++) {
                  total += dataset[i].value;
                }
                let arr = ['{a|合计\n}{b|' + total + '}'];
                return arr.join('\n');
              },
              textStyle: {
                rich: {
                  a: {
                    fontSize: 14,
                    color: '#5f5f5f',
                    lineHeight: 24,
                  },
                  b: {
                    fontSize: 36,
                    color: '#313D5F',
                    fontWidth: 'bold',
                    lineHeight: 54,
                  },
                },
              },
            },
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ]),
        this.initChart('category', 'walden', option);
    },
    //渲染任务数量柱状图
    initIndustry(List) {
      let option = {};
      (option.xAxis = {
        type: 'category',
        data: List.map(item => item.date),
        axisTick: {
          alignWithLabel: true,
        },
      }),
        (option.yAxis = {
          type: 'value',
          minInterval: 1,
        }),
        (option.grid = {
          left: '10%',
          top: '10%',
          right: '10%',
          bottom: '10%',
        }),
        (option.tooltip = {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          borderColor: '#5D80FC',
          padding: 10,
          formatter: '{b}<br/>任务数量: {c}',
        }),
        (option.series = [
          {
            data: List.map(item => {
              return {
                value: item.value,
              };
            }),
            type: 'bar',
            itemStyle: {
              color: '#3155EF',
            },
            barWidth: '50%',
          },
        ]),
        this.initChart('industry', 'walden', option);
    },
    initContactNum(List) {
      let option = {};
      (option.xAxis = {
        type: 'category',
        data: List.map(item => item.date),
      }),
        (option.yAxis = {
          type: 'value',
          minInterval: 1,
        }),
        (option.grid = {
          left: '10%',
          top: '10%',
          right: '10%',
          bottom: '10%',
        }),
        (option.tooltip = {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          borderColor: '#5D80FC',
          padding: 10,
          formatter: '{b}<br/>新增联系人数量: {c}',
        }),
        (option.series = [
          {
            data: List.map(item => item.value),
            type: 'line',
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: 'rgba(95, 142, 248, 0)',
                },
                {
                  offset: 0,
                  color: 'rgba(49, 85, 239, 0.5)',
                },
              ]),
            },
            lineStyle: {
              show: true,
              color: '#3155EF',
              width: 3,
            },
            symbolSize: 6,
            itemStyle: {
              normal: {
                show: true,
                borderColor: '#3155EF', //拐点边框颜色
                borderWidth: 2, //拐点边框大小
              },
              emphasis: {
                borderWidth: 1,
                show: true,
                color: '#3155EF', //hover拐点颜色定义
                borderColor: '#fff',
              },
            },
          },
        ]),
        this.initChart('ContactNum', 'walden', option);
    },
    initCompanyNum(List) {
      let option = {};
      (option.xAxis = {
        type: 'category',
        data: List.map(item => item.date),
      }),
        (option.yAxis = {
          type: 'value',
          minInterval: 1,
        }),
        (option.grid = {
          left: '10%',
          top: '10%',
          right: '10%',
          bottom: '10%',
        }),
        (option.tooltip = {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
          borderColor: '#5D80FC',
          padding: 10,
          formatter: '{b}<br/>新增公司数量: {c}',
        }),
        (option.series = [
          {
            data: List.map(item => item.value),
            type: 'line',
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 1,
                  color: 'rgba(95, 142, 248, 0)',
                },
                {
                  offset: 0,
                  color: 'rgba(49, 85, 239, 0.5)',
                },
              ]),
            },
            lineStyle: {
              color: '#3155EF',
              width: 2,
            },
            symbolSize: 6,
            itemStyle: {
              normal: {
                borderColor: '#3155EF', //拐点边框颜色
                borderWidth: 2, //拐点边框大小
              },
              emphasis: {
                color: '#3155EF', //hover拐点颜色定义
                borderColor: '#fff',
                borderWidth: 1,
              },
            },
          },
        ]),
        this.initChart('CompanyNum', 'walden', option);
    },
    initContactMap(List) {
      echarts.registerMap('china', china);
      let option = {};
      //以下是地图标题
      (option.title = {
        text: '',
        subtext: '',
        left: 'center',
      }),
        //以下是提示框组件
        (option.tooltip = {
          show: true,
          trigger: 'item',
          borderColor: '#5D80FC',
          padding: 10,
          formatter: `联系人数量<br/>{b}:{c}`,
        }),
        //左侧小图设置
        (option.visualMap = {
          max: this.Maxcount,
          text: ['High', 'Low'],
          realtime: false,
          calculable: false,
          hoverLink: false,
          inRange: {
            color: ['#d6e2ff', '#2c3dab'],
          },
        }),
        (option.geo = {
          map: 'china',
          roam: false,
          label: {
            emphasis: {
              show: true,
            },
            normal: {
              show: false, // 是否显示对应地名
            },
          },
          itemStyle: {
            normal: {
              areaColor: '#E2E2E2', //地图颜色
              borderColor: '#C2C2C2', //地图边线颜色
              borderWidth: 1, //设置外层边框
              shadowBlur: 0,
              //                shadowOffsetY: 8,
              shadowOffsetX: 0,
              shadowColor: '#01012a',
            },
            emphasis: {
              areaColor: '#3e9fed', //鼠标移入颜色
              shadowOffsetX: 0,
              shadowOffsetY: 15,
              shadowBlur: 8,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
        }),
        (option.series = [
          {
            type: 'scatter',
            coordinateSystem: 'geo', // 对应上方配置
          },
          {
            name: '', // 浮动框的标题
            type: 'map',
            geoIndex: 0,
            data: List,
          },
        ]),
        this.initChart('ContactMap', 'walden', option);
    },
    initContactIndustry(List) {
      let selectOption = {};
      for (var j = 0; j < List.length; j++) {
        if (j < 8) {
          selectOption[List[j].name] = true;
        } else {
          selectOption[List[j].name] = false;
        }
      }
      let option = {};
      (option.title = {
        text: '',
        left: 'center',
      }),
        (option.tooltip = {
          trigger: 'item',
        }),
        (option.legend = {
          orient: 'horizontal',
          bottom: '10%',
          icon: 'circle',
          type: 'scroll', //分页类型
          height: 50,
          pageIconSize: 15,
          animation: true,
          selected: selectOption,
        }),
        (option.series = [
          {
            name: '行业',
            type: 'pie',
            radius: '50%',
            center: ['50%', '40%'],
            data: List,
            avoidLabelOverlap: true,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ]),
        this.initChart('ContactIndustry', 'walden', option);
    },
    initContactPosition(List) {
      let selectOption = {};
      for (var j = 0; j < List.length; j++) {
        if (j < 8) {
          selectOption[List[j].name] = true;
        } else {
          selectOption[List[j].name] = false;
        }
      }

      let option = {};
      (option.title = {
        text: '',
        left: 'center',
      }),
        (option.tooltip = {
          trigger: 'item',
        }),
        (option.legend = {
          orient: 'horizontal',
          bottom: '10%',
          icon: 'circle',
          type: 'scroll', //分页类型
          height: 50,
          pageIconSize: 15,
          animation: true,
          selected: selectOption,
        }),
        (option.series = [
          {
            name: '职位',
            type: 'pie',
            radius: '50%',
            center: ['50%', '40%'],
            data: List,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ]),
        this.initChart('ContactPosition', 'walden', option);
    },
    handleFnCilckDay(num) {
      this.dayButton = num;
      const strTime = num === 0 ? 0 : 86400000 * num;
      const endTime = num === 0 ? 0 : 86400000;
      this.TimePick[0] = moment(new Date(new Date().getTime() - strTime));
      this.TimePick[1] = moment(new Date(new Date().getTime() - endTime));
      this.TimeData.statStartTime = this.TimePick[0]
        .format('YYYY-MM-DD')
        .toString();
      this.TimeData.statEndTime = this.TimePick[1]
        .format('YYYY-MM-DD')
        .toString();
      this.getInfoCount();
      this.getAreaCount();
    },
    handleFnOk() {
      this.dayButton = '';
      this.TimeData.statStartTime = this.TimePick[0]
        .format('YYYY-MM-DD')
        .toString();
      this.TimeData.statEndTime = this.TimePick[1]
        .format('YYYY-MM-DD')
        .toString();
      // console.log(this.TimePick[1].format('d'));
      this.getInfoCount();
      this.getAreaCount();
    },
    getWeek(date) {
      // 参数时间戳
      switch (date) {
        case 'MONDAY':
          return '星期一';
        case 'TUESDAY':
          return '星期二';
        case 'WEDNESDAY':
          return '星期三';
        case 'THURSDAY':
          return '星期四';
        case 'FRIDAY':
          return '星期五';
        case 'SATURDAY':
          return '星期六';
        case 'SUNDAY':
          return '星期日';
      }
    },
    handleChangePage() {},
  },
};
</script>

<style lang="less" scoped>
.iss-dashboard {
  padding: 16px;
  .dashboard-wrapper {
    padding: 10px;
    margin: -10px;
    height: calc(100vh - 68px);
    overflow-y: auto;
    /deep/.ant-table-tbody > tr > td,
    /deep/.ant-table-thead > tr > th {
      padding: 4px 16px;
    }
    /deep/.ant-pagination-prev,
    /deep/.ant-pagination-next,
    /deep/.ant-pagination-item,
    /deep/.ant-pagination-jump-prev,
    /deep/.ant-pagination-jump-next {
      width: 24px;
      min-width: 24px;
      height: 24px;
      line-height: 24px;
      a {
        padding: 0;
      }
    }
    /deep/.ant-table-pagination.ant-pagination {
      margin: 16px 10px 0 0;
    }
    // 图表
    .pic {
      //border-bottom-right-radius: 0;
      //border-top-right-radius: 0;
      //color: #86909C;
      width: 24px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      //color: #86909C;
      line-height: 20px;
    }
    // 列表
    .List {
      //border-bottom-left-radius: 0;
      //border-top-left-radius: 0;
      //color: #86909C;
      width: 24px;
      height: 20px;
      font-size: 12px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      //color: #86909C;
      line-height: 20px;
    }
  }
  .anticon-question-circle {
    opacity: 0.4;
  }
  .title {
    min-height: 26px;
  }
}
.statistics-card {
  border-radius: 4px;
  padding: 20px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
  .statistics-title {
    //padding: 10px 12px;
    font-size: 16px;
  }
  .statistics-item {
    //padding: 12px 20px;
    //width: 100%;
    text-align: center;
    background-color: #ffffff;
    align-items: center;
  }
  .imgBox {
    img {
      height: 80px;
    }
  }
}
.statistics-card[flex-attr] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.graph-card {
  height: 352px;
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  //box-shadow: @box-shadow-base;
}
.change {
  display: inline-block;
  float: right;
  span {
    cursor: pointer;
  }
}
.words {
  color: #999999;
}
.number {
  color: #313d5f;
}
.word-up {
  color: #f27572;
}
.word-down {
  color: #86d8b5;
}
.flex-align {
  align-items: baseline;
}
/deep/ .ant-spin-nested-loading {
  height: 260px;
}
.tips {
  /deep/.ant-tooltip-content {
    width: 180px;
  }
}

.iss-grid {
  border-radius: 8px;
  box-shadow: 0px 3px 10px 0px @primary-3;
}
.dividerStyle {
  width: 1px;
  height: 10px;
  border-left: 1px solid #c9cdd4;
}
.fontStyle {
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #313d5f;
  line-height: 24px;
}
.font-T {
  height: 22px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #313d5f;
  line-height: 22px;
}
.dividerStyle-T {
  width: 1px;
  height: 64px;
  background: #f1f1f1;
}
.imgBox {
  width: 15%;
  .icon {
    font-size: 28px;
  }
}
</style>
